import {Col, Input, Row} from "reactstrap";
import React from "react";
import Select from "react-select";
import {reactSelectStyles} from "../../../utils";
import InputRow from "./inputRow";

export function BasicEmailFields(props) {
    const propertyOptions = [
        ...props.propertyList.map(p => ({
            value: p.id,
            label: p.name,
        }))
    ]

    const selectedPropertyOption = props.emailContent.property_id && propertyOptions.filter(o => o.value === props.emailContent.property_id)
    return <div>
        <InputRow
            type="text" placeholder="First Name"
            value={props.emailContent.first_name}
            onChange={value => props.onChange({first_name: value})}
            required/>

        <InputRow
            type="text" placeholder="Last Name"
            value={props.emailContent.last_name}
            onChange={value => props.onChange({last_name: value})}
            required
        />

        <InputRow
            type="text" placeholder="Contact Number"
            value={props.emailContent.contact_number}
            onChange={value => props.onChange({contact_number: value})}
            required
        />

        <InputRow
            type="email" placeholder="Email Address"
            value={props.emailContent.email_address}
            onChange={value => props.onChange({email_address: value})}
            required
        />

        <Row className="px-4 py-2">
            <Col>
                <strong>I would like to learn more about...</strong>
            </Col>
        </Row>


        <InputRow
            required
            value={selectedPropertyOption}
            onChange={o => {
                props.onChange({property_id: o.value})
            }}
            className="react-select"
            renderer={(props) => <Select
                {...props}
                styles={reactSelectStyles}
                options={propertyOptions}
            />}
        />


        <InputRow
            type="textarea" placeholder="Message" rows={5}
            value={props.emailContent.message}
            onChange={value => props.onChange({message: value})}
            required
        />
    </div>;
}