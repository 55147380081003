import React, {useState} from "react";
import {Button, Col, Container, Modal, ModalBody, Row} from "reactstrap";
import AgentModalHeader from "../components/modalHeader";
import ModalInquirySuccess from "../components/modalInquirySuccess";
import {INQUIRY_SAMPLE_COMPUTATION} from "../../../constants";
import PrivacyPolicy from "../../../commonComponents/privacyPolicy";

export default function SampleComputationModal(props) {
    const [isSending, setIsSending] = useState(false)
    const [isSent, setIsSent] = useState(false)

    const send = () => {
        setIsSending(true)
        props.inquiry({
            inquiry_type: INQUIRY_SAMPLE_COMPUTATION
        }).then(() => {
            setIsSent(true)
        }).finally(() => {
            setIsSending(false)
        })
    }

    if (isSent) {
        return <ModalInquirySuccess
            toggle={() => {
                props.setEmailModalOpen(false)
                setIsSending(false)
                setIsSent(false)
            }}
            url={props.profilePhotoUrl}
            isOpen={props.isOpen}
        />
    }

    return <Modal
        centered scrollable
        isOpen={props.isOpen}
        size="lg"
        className="sales-profile-modal"
        toggle={() => {
            props.setEmailModalOpen(false)
        }}
    >
        <AgentModalHeader
            title={"Sample computation"}
            subtitle={"Fill-up the form below and I'll email you a sample computation."}
            profilePhotoUrl={props.profilePhotoUrl}
            close={() => props.setEmailModalOpen(false)}/>
        <ModalBody className="h-100">
            <Container className="px-4 py-2">
                {props.basicEmailFields}

                <Row className="pt-2 px-4">
                    <Col>
                        <PrivacyPolicy linkText={"By continuing, you agree to our Terms and Privacy Policy."}/>
                    </Col>
                </Row>

                <Row className="px-4 py-2">
                    <Col>
                        <Button size="lg" color={"primary"} disabled={isSending} onClick={send}>
                            Send{isSending && "ing..."}
                        </Button>
                    </Col>
                </Row>

            </Container>
        </ModalBody>
    </Modal>
}