import {encode as base64_encode} from "base-64";

export const createNonce = () => {
    return base64_encode(Math.floor(Date.now() / 1000).toString());
}

export const reactSelectStyles = {
    control: styles => ({
        ...styles,
        borderRadius: "0",
        borderColor: "rgb(238, 238, 238)",
        color: '#666'
    }),
    singleValue: styles => ({
        ...styles,
        color: "#666"
    })
}


export const reactSelectSearchStyles = {
    control: styles => ({
        ...styles,
        borderRadius: "0",
        borderColor: "rgb(238, 238, 238)",
        color: '#666'
    }),
    singleValue: styles => ({
        ...styles,
        color: "#666",
        fontSize: "0.8em"
    }),
    placeholder: styles => ({
        ...styles,
        fontSize: "0.8em",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    }),
    option: styles => ({
        ...styles,
        fontSize: "0.8em"
    })
}
