import React, {useMemo, useState} from "react";
import {Col, Container, Input, Row} from "reactstrap";
import Inventory from "./inventory";
import Select from "react-select";
import {reactSelectSearchStyles, reactSelectStyles} from "../../utils";
import * as JsSearch from 'js-search';

const stemmer = require('porter-stemmer').stemmer;

function inventoryLocation(i) {
    return [i.province, i.region].join(', ')
}

export default function InventoryList(props) {
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [searchText, setSearchText] = useState('');

    const search = useMemo(() => {
        const search = new JsSearch.Search('property')
        // search.tokenizer = new JsSearch.StemmingTokenizer(
        //     stemmer,
        //     // new JsSearch.StopWordsTokenizer(
        //         new JsSearch.SimpleTokenizer()
        //     // )
        // );
        search.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
        search.addIndex('name')
        search.addIndex('description')
        search.addIndex('address')
        search.addIndex('region')
        search.addIndex('province')
        if (props.inventoryList) search.addDocuments(props.inventoryList)
        return search
    }, [props.inventoryList])

    const searchedProperties = useMemo(() => {
        return searchText ? search.search(searchText) : props.inventoryList
    }, [search, searchText, props.inventoryList])

    const filteredProperties = useMemo(() => {
        return selectedLocation ? [
            ...searchedProperties.filter(i => inventoryLocation(i) === selectedLocation.value)
        ] : searchedProperties

    }, [searchedProperties, selectedLocation])

    if (!props.inventoryList) {
        return null
    }

    const uniqueLocations = [...new Set([...searchedProperties.map(i => inventoryLocation(i))])]
    const locationOptions = [
        ...uniqueLocations.map(location => ({
            value: location,
            label: location
        }))
    ]

    const displayProperties = () => {
        if (filteredProperties.length === 0) {
            return <Row>
                <Col className="text-center p-4">
                    No properties found.
                </Col>
            </Row>
        }
        return filteredProperties.map(inventory => <Inventory
            key={inventory.id}
            track={props.track}
            inquiry={props.inquiry}
            setEmailModalOpen={props.setEmailModalOpen}
            data={inventory}
            profilePhotoUrl={props.profilePhotoUrl}
            basicEmailFields={props.basicEmailFields}
            emailContentOk={props.emailContentOk}
            selectProperty={props.selectProperty}
        />)
    }

    return <Container className="property-list mt-4 pt-4">
        <Row className="border-bottom align-items-center pb-2 px-3">
            <Col lg={6}>
                <h1>Featured Properties</h1>
            </Col>
            <Col lg={3} md={6} className="pb-2">
                <Input
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                    type="text" placeholder="Search" className="header-search-input"/>
            </Col>
            <Col lg={3} md={6} className="pb-2">
                <Select
                    options={locationOptions}
                    value={selectedLocation}
                    isClearable={true}
                    onChange={o => setSelectedLocation(o)}
                    styles={reactSelectSearchStyles}
                    placeholder={'Filter by Property Location'}
                />
            </Col>
        </Row>

        {
            displayProperties()
        }
    </Container>
}