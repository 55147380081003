import './App.css';
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import AgentSite from "./routes/agentSite";
import {v4 as uuidv4} from 'uuid';
import {useCookies} from "react-cookie";
import {useEffect} from "react";
import NotFound from "./routes/notFound";
import {Helmet} from "react-helmet";
import LoadingPage from "./routes/loadingPage";
import {QueryClient, QueryClientProvider} from "react-query";


function App() {
    const [cookies, setCookie, removeCookie] = useCookies(['session']);
    const setSessionCookieIfNotSet = () => {
        if (!cookies.session) {
            console.log("setting session")
            const today = new Date()
            setCookie(
                'session',
                uuidv4(),
                {
                    domain: window.location.hostname,
                    expires: new Date(today.getFullYear() + 1, today.getMonth(), today.getDate())
                }
            )
        }
    }

    useEffect(() => {
        setSessionCookieIfNotSet()
    }, [cookies.session])

    const queryClient = new QueryClient()

    return (
        <QueryClientProvider client={queryClient}>
            <div className="App">
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Active Group</title>
                </Helmet>

                <header className="App-header">
                    {
                        !cookies.session ? <LoadingPage/> : <Router>
                            <Routes>
                                <Route path="/" element={<NotFound/>}/>
                                <Route path="/:agentUrl" element={<AgentSite/>}/>
                                <Route path="*" element={<Navigate to="/"/>}/>
                            </Routes>
                        </Router>
                    }
                </header>
            </div>
        </QueryClientProvider>
    );
}

export default App;
