import {Container, Modal, ModalBody} from "reactstrap";
import AgentModalHeader from "../components/modalHeader";
import React from "react";
import * as PropTypes from "prop-types";

function SiteMapModal(props) {
    return <Modal
        centered scrollable
        isOpen={props.open}
        size="xl"
        className="sales-profile-modal"
        toggle={props.toggle}
    >
        <AgentModalHeader
            title={"Location and Site Plan"}
            subtitle={<span>See location and site plan to {props.name}</span>}
            profilePhotoUrl={props.profilePhotoUrl}
            close={props.close}/>
        <ModalBody className="h-100">
            <Container className="px-4 py-2">
                {
                    props.sitePlanPhotos.map(p => <img
                        key={p.photo}
                        alt={"Site plan photo " + p.title}
                        src={p.photo}
                        className="img-fluid"
                    />)
                }


                {
                    !!props.googleMapUrl && <iframe
                        title="Google Maps"
                        src={props.googleMapUrl}
                        width="100%" height="450" style={{border: "0"}} allowFullScreen="" loading="lazy"></iframe>
                }
            </Container>


        </ModalBody>
    </Modal>;
}

SiteMapModal.propTypes = {
    open: PropTypes.bool,
    toggle: PropTypes.func,
    googleMapUrl: PropTypes.any,
    sitePlanPhotos: PropTypes.array,
    profilePhotoUrl: PropTypes.any,
    close: PropTypes.func,
};

export default SiteMapModal;