import React, {useEffect, useState} from "react";
import {Col, FormFeedback, Input, Row} from "reactstrap";
import * as PropTypes from "prop-types";


const passesPattern = (pattern, value) => {
    const re = new RegExp(pattern);
    console.log(pattern, value, re.test(value))
    return re.test(value)
}


export default function InputRow(props) {
    const [hadFocus, setHadFocus] = useState(false);
    const [shouldCheck, setShouldCheck] = useState(false);
    const [showToolTip, setShowToolTip] = useState(false)

    const noValueRequired = props.required && !props.value;
    const patternError = props.pattern && props.value && !passesPattern(props.pattern, props.value);
    const hasError = noValueRequired || patternError;
    useEffect(() => {
        if (props.setHasError) {
            props.setHasError(hasError)
        }
        if (hasError) {
            setShowToolTip(true)
        }
    }, [hasError])

    useEffect(() => {
        !!props.errorMessage && setShowToolTip(true)
    }, [props.errorMessage])

    const showError = hadFocus && shouldCheck && (hasError || props.errorMessage);

    const classNames = [
        (props.className ? props.className : ""),
        "flex-fill",
        (showError ? "error" : ""),
    ].join(' ')

    useEffect(() => {
        // auto check field after 3 second idle time
        setShouldCheck(false)
        const delayDebounceFn = setTimeout(() => {
            setShouldCheck(true)
            setShowToolTip(true)
        }, 3000)

        return () => clearTimeout(delayDebounceFn)
    }, [props.value])

    useEffect(() => {
        // auto hide tooltip after 3 seconds
        if (showToolTip && !props.staticError) {
            const delayDebounceFn = setTimeout(() => {
                setShowToolTip(false)
            }, 3000)

            return () => clearTimeout(delayDebounceFn)
        }
    }, [showToolTip, shouldCheck, props.errorMessage])


    const renderProps = {
        ...props,
        invalid: showError,
        className: classNames,
        value: props.value,
        onFocus: () => setHadFocus(true),
        onBlur: () => setShouldCheck(true)
    }
    delete renderProps["invalidPatternError"]
    delete renderProps["setHasError"]
    delete renderProps['staticError']

    const inputRender = props.renderer ? props.renderer({
        ...renderProps,
        className: classNames
    }) : <Input
        {...renderProps}
        onChange={e => {
            props.onChange(e.target.value)
        }}
    />

    const errorFeedback = <FormFeedback tooltip>
        {noValueRequired && <>{props.label} is required.</>}
        {patternError && <>{props.invalidPatternError ? props.invalidPatternError : "Invalid format."}</>}

        {props.errorMessage}
    </FormFeedback>
    return <Row className="px-4 py-2">
        <Col>
            {inputRender}

            {
                showToolTip && errorFeedback
            }
        </Col>
    </Row>
}

InputRow.propTypes = {
    value: PropTypes.any,
    renderer: PropTypes.any,
    label: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    setHasError: PropTypes.func,
    invalidPatternError: PropTypes.string
};
