import {Col, Container, Modal, ModalBody, Row} from "reactstrap";
import ProfilePhoto from "../profilePhoto";
import React from "react";
import * as PropTypes from "prop-types";

function ModalInquirySuccess(props) {
    return <Modal centered isOpen={props.isOpen} size="lg" className="sales-profile-modal" toggle={props.toggle}>
        <div className="email-modal-header modal-header text-white">
            <button type="button" className="btn-close-white btn-close align-top" data-bs-dismiss="modal"
                    onClick={props.toggle} aria-label="Close"/>
        </div>
        <ModalBody className="h-100">
            <Container fluid>
                <Row className="p-4">
                    <Col lg={9}>
                        <h1 className="color-highlight">Thank You!</h1>
                        I'll get back to you as soon as possible. Have a nice day.
                    </Col>
                    <Col lg={3} className="justify-content-end d-flex">
                        <ProfilePhoto url={props.url} shrink={1}/>
                    </Col>
                </Row>
            </Container>
        </ModalBody>
    </Modal>;
}

ModalInquirySuccess.propTypes = {
    toggle: PropTypes.func,
    isOpen: PropTypes.bool,
    url: PropTypes.any
};
export default ModalInquirySuccess