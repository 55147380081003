import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "bootstrap/dist/css/bootstrap.min.css"
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import axios from "axios";


const BASE_URL = process.env.REACT_APP_API_URL
axios.defaults.baseURL = BASE_URL

Sentry.init({
    dsn: "https://894011d5270e4e8ab6b6a84b1c5848a0@o102310.ingest.sentry.io/6388932",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
