import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "reactstrap";

import ActiveGroupLogo from "../../assets/images/activegroup-logo-banner-colored.png";
import ActiveGroupMission from "../../assets/images/active-group-mission-v1-1.png";
import {ParallaxProvider, useParallax} from "react-scroll-parallax";

const MaxPaddingHeight = 150;


export default function ActiveGroupInfo() {
    // const parallax = useParallax({translateY:[20, -15]})
    return <Container className="active-group-info mb-5 px-4 pb-5">
        <Row className="">
            <Col>
                    <Row className="px-3 pt-5">
                        <Col>
                            <img src={ActiveGroupLogo} className="img-fluid banner-logo" width={250}/>
                        </Col>
                    </Row>
                <div className="active-group-info-text-container">
                    <Row>
                        <Col>
                            <p>
                                The Active Group is a fully-integrated and full-service real estate conglomerate, with
                                an impressive synergy of resources in architecture, planning, land development,
                                construction, sales & marketing and insurance.
                            </p>
                            <p>
                                For over 50 years, the Active Group has transformed more than 2,000 hectares of raw land
                                into prime commercial and residential areas in Metro Manila, Rizal, Bulacan, Cavite,
                                Laguna, Batangas and Negros Occidental. Its many real estate developments range from
                                socialized housing projects to premium resort communities.
                            </p>
                            {/*<p>*/}
                            {/*    For more information, visit{" "}*/}
                            {/*    <strong>*/}
                            {/*        <a href="https://www.activegroup.com.ph" target="_blank">www.activegroup.com.ph</a>*/}
                            {/*    </strong>*/}
                            {/*</p>*/}
                        </Col>
                    </Row>
                </div>

            </Col>
            {/*<Col id="active-group-mission-img-container">*/}
            {/*    <div ref={parallax.ref}>*/}
            {/*    <img*/}
            {/*        src={ActiveGroupMission} className="img-fluid" id="active-group-mission-img"*/}
            {/*    /></div>*/}
            {/*</Col>*/}
        </Row>
    </Container>
}