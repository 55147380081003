import React, {useState} from "react";
import {Button, Col, Container, Row} from "reactstrap";
import activeGroupAccredited from "../../assets/images/horizontal-banner.png"
import {BASE_URL, TRACK_BROKER_URL, TRACK_EMAIL, TRACK_LETS_TALK} from "../../constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelopeOpenText, faMousePointer, faSquarePhone} from "@fortawesome/free-solid-svg-icons";
import {faSquare} from "@fortawesome/free-regular-svg-icons";
import ProfilePhoto from "./profilePhoto";
import PrivacyPolicy from "./components/privacyPolicy";

export default function AgentInfo(props) {
    const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
    const {salesProfile} = props;

    return <div className={"agent-info-container" + (props.bottom ? " bottom" : "")}>
        <Container className="px-3">
            <Row className={"py-4 mx-2"}>
                <Col lg={3} className="px-2 pe-3 pb-3">
                    <Row className="mb-3">
                        <Col className="justify-content-center d-flex py-3">
                            <ProfilePhoto url={salesProfile.profile_photo_url}/>
                        </Col>
                    </Row>

                    <Row className="text-bottom-border mb-4">
                        <Col onClick={() => {
                            props.track(TRACK_LETS_TALK);
                            window.open("tel:" + salesProfile.contact_number, '_self')
                        }}>
                            <FontAwesomeIcon icon={faSquarePhone} className="color-highlight agent-contact-icon"/>
                            <span className="agent-contact">
                              {salesProfile.contact_number}
                            </span>
                        </Col>
                    </Row>

                    <Row className="text-bottom-border mb-3">
                        <Col onClick={() => {
                            props.track(TRACK_EMAIL);
                            props.setEmailModalOpen(true);
                        }}>
                            <FontAwesomeIcon icon={faEnvelopeOpenText} className="color-highlight agent-contact-icon"/>
                            <span className="agent-contact">
                                Send me an email.
                            </span>
                        </Col>
                    </Row>
                </Col>
                <Col lg={6} className="px-3 pt-3">

                    <Row>
                        <Col>
                            <h2 className="name">{salesProfile.name}</h2>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col>
                            <p className="agent-url-container">
                                <span className="fa-layers fa-fw ps-2 pe-5 fa-shrink-2">
                                    <FontAwesomeIcon icon={faSquare} transform="up-4"
                                                     style={{transform: "scale(1.5, 0.8)"}}/>
                                    <FontAwesomeIcon icon={faMousePointer} inverse transform="right-5 down-4"/>
                                </span>

                                <span className="agent-url" onClick={() => {
                                    props.track(TRACK_BROKER_URL).then(() => {
                                        window.location = BASE_URL + salesProfile.url_path;
                                    })
                                }}>
                                    {BASE_URL}
                                    <strong>{salesProfile.url_path}</strong>
                                </span>
                            </p>
                        </Col>
                    </Row>


                    {
                        salesProfile.public_profile_description && <Row>
                            <Col>
                                <p className="description">
                                    {salesProfile.public_profile_description}
                                </p>
                            </Col>
                        </Row>
                    }
                </Col>
                <Col lg={3} className="p-3">

                    <Row>
                        <Col className="text-center">
                            <img src={activeGroupAccredited} className="img-fluid accreditation"/>
                        </Col>
                    </Row>

                    {
                        salesProfile.affiliation && <Row>
                            <Col className="description">
                                <strong>Broker Affiliation:</strong> {salesProfile.affiliation}
                            </Col>
                        </Row>
                    }

                    {
                        salesProfile.licenses && <Row>
                            <Col className="description">
                                {salesProfile.licenses}
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
            {
                props.bottom && <Row className="small align-items-center mb-2">
                    <Col>
                        <small>v{process.env.REACT_APP_VERSION} © {new Date().getFullYear()} Copyright</small>
                    </Col>
                    <Col className="text-end">
                        <Button color="text" onClick={() => setPrivacyPolicyOpen(true)}>
                            Privacy Policy
                        </Button>
                        <PrivacyPolicy isOpen={privacyPolicyOpen} toggle={() => setPrivacyPolicyOpen(false)}/>
                    </Col>
                </Row>
            }
        </Container>

    </div>
}