import React from "react";
import {Button, Col, Container, Row} from "reactstrap";


import banner from "../assets/images/horizontal-banner.png";


export default function NotFound(props) {
    return <Container
        fluid
        className="align-items-center vh-100 d-flex justify-content-center text-center"
        style={{backgroundColor: "#002463", color: "white"}}>
        <div>
            <Row className="m-5 p-3">
                <Col>
                    <img src={banner} style={{width: "200px"}}/>
                </Col>
            </Row>
            <Row className="m-5 p-3">
                <Col>
                    The page you are looking for is currently unavailable.
                </Col>
            </Row>
            <Row className="m-5 p-3">
                <Col>
                    <a
                        href="https://activegroup.com.ph/"
                        className="btn-primary text-white p-2 px-4 text-decoration-none" >
                        Active Group Website
                    </a>
                </Col>
            </Row>
        </div>
    </Container>
}