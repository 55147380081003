import React from "react";
import {Button, Col, Container, Row, Spinner} from "reactstrap";


import banner from "../assets/images/horizontal-banner.png";


export default function LoadingPage(props) {
    return <Container
        fluid
        className="align-items-center vh-100 d-flex justify-content-center text-center"
        style={{backgroundColor: "#002463", color: "white"}}>
        <div>
            <Row className="m-5 p-3">
                <Col>
                    <img src={banner} style={{width: "200px"}}/>
                </Col>
            </Row>
            <Row className="m-5 p-3">
                <Col>
                    <h2>
                        <Spinner/>
                    </h2>
                </Col>
            </Row>
        </div>
    </Container>
}