import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons";


export default function ScrollToTop() {
    const [scrollTopVisible, setScrollTopVisible] = useState(false)

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY * 1.5 > window.outerHeight) {
                setScrollTopVisible(true)
            } else {
                setScrollTopVisible(false)
            }
        }
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, {passive: true});
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return <div
        onClick={() => {
            window.scrollTo(0, 0)
        }}
        className={"d-flex align-items-center justify-content-center scroll-to-top " + (scrollTopVisible ? "show" : "")}>
        <FontAwesomeIcon icon={faChevronUp} className="text-white"/>
    </div>
}