export const BASE_URL = 'https://sales.activegroup.com.ph/'

export const INQUIRY_GENERAL = 'general';
export const INQUIRY_SAMPLE_COMPUTATION = 'sample_computation';
export const INQUIRY_SITE_VISIT = 'site_visit'

export const TRACK_PAGE_LOAD = 'page_load'

export const TRACK_BROKER_URL = 'broker_url'

export const TRACK_LETS_TALK = 'lets_talk'
export const TRACK_INQUIRE = 'inquire_today'
export const TRACK_EMAIL = 'email'

export const TRACK_VISIT = 'visit'
export const TRACK_LOCATION = 'location'
export const TRACK_MORE_INFO = 'more_info'
export const TRACK_SAMPLE_COMPUTATION = 'sample_computation'
export const TRACK_CAROUSEL = 'carousel'

export const PRIVACY_POLICY_ID = 'microsite';
