import React, {useEffect, useState} from "react";

import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {Col, Row} from "reactstrap";
import AgentInfo from "./agentInfo";
import axios from "axios";
import {useCookies} from "react-cookie";
import Email from "./email";
import {createNonce} from "../../utils";
import ProfilePhoto from "./profilePhoto";
import InventoryList from "./InventoryList";
import {BasicEmailFields} from "./components/basicEmailFields";
import ActiveGroupInfo from "./ActiveGroupInfo";
import {ParallaxProvider} from "react-scroll-parallax";
import {TRACK_INQUIRE, TRACK_PAGE_LOAD} from "../../constants";
import ScrollToTop from "./components/scrollToTop";
import AnnouncementModal from "./components/announcementModal";
import LoadingPage from "../loadingPage";
import {toast, ToastContainer} from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';
import NotFound from "../notFound";
import {Helmet} from "react-helmet";


export default function AgentSite() {
    const {agentUrl} = useParams()
    const [salesProfile, setSalesProfile] = useState({});

    const [isLoading, setIsLoading] = useState(true);
    const [loadingAttempt, setLoadingAttempt] = useState(0)
    const [hasLoadingError, setHasLoadingError] = useState(false);

    const [isEmailModalOpen, setEmailModalOpen] = useState(false);

    const [cookies, setCookie, removeCookie] = useCookies([]);
    const [emailContent, setEmailContent] = useState({
        first_name: "",
        last_name: "",
        contact_number: "",
        email_address: "",
        property_id: null,
        message: ""
    })
    const [searchParams, setSearchParams] = useSearchParams();
    const [announcement, setAnnouncement] = useState();
    const [announcementSeen, setAnnouncementSeen] = useState(false)
    const [showAnnouncement, setShowAnnouncement] = useState(false)

    const navigate = useNavigate()

    const referrer = searchParams.get('ref')

    const getSalesProfile = () => {
        setLoadingAttempt(loadingAttempt+1)
        axios.get('sales-profile/' + agentUrl + '/').then(response => {
            setSalesProfile(response.data)
            setIsLoading(false)
        }).catch((err) => {
            if (err.response?.status === 404 || loadingAttempt >= 3) {
                setHasLoadingError(true)
                setIsLoading(false)
            } else {
                getSalesProfile()
            }
        }).finally(() => {
        })
    }

    const getSiteAnnouncement = () => {
        axios.get('sales-profile/' + agentUrl + '/announcement/').then(response => {
            setAnnouncement(response.data)
            if (!announcementSeen) {
                setShowAnnouncement(true)
            }
        }).catch(() => {

        })
    }

    const trackAnnouncement = (announcementId) => {
        const encodedNonce = createNonce()
        axios.post('sales-profile/' + agentUrl + '/track_announcement/', {
            nonce: encodedNonce,
            "session": cookies.session,
            "referrer": referrer ? referrer : "direct",
            "announcement_id": announcementId
        }).then(response => {
            console.log(response.data)
        }).catch(err => {
            console.log(err)
        })
    }

    const track = (trackType, extra_data) => {
        const encodedNonce = createNonce()
        if (!extra_data) {
            extra_data = {}
        }
        return axios.post('sales-profile/' + agentUrl + '/track/', {
            "type": trackType,
            nonce: encodedNonce,
            "session": cookies.session,
            "referrer": referrer ? referrer : "direct",
            ...extra_data
        }).then(response => {
            console.log(response.data)
        }).catch(err => {
            console.log(err)
        })
    }

    const inquiry = (data) => {
        const encodedString = createNonce()
        return axios.post('sales-profile/' + agentUrl + '/inquiry/', {
            ...emailContent,
            ...data,
            nonce: encodedString,
            "session": cookies.session,
            "referrer": referrer
        }).then(response => {
            setEmailContent({...emailContent, message: ""})
            return response
        }).catch(err => {
            toast.error("Something went wrong. Please try again.")
            throw err
        })
    }

    const selectProperty = propertyId => {
        setEmailContent({...emailContent, property_id: propertyId})
    }

    useEffect(() => {
        getSalesProfile();
    }, []);
    useEffect(() => {
        if (salesProfile.name) {
            track(TRACK_PAGE_LOAD);
        }
    }, [salesProfile.name])

    useEffect(() => {
        if (salesProfile.name) {
            getSiteAnnouncement()
        }
    }, [salesProfile.name])

    const basicEmailFields = <BasicEmailFields
        emailContent={emailContent}
        onChange={newData => setEmailContent({...emailContent, ...newData})}
        propertyList={salesProfile["properties"]}
    />
    const emailContentOk = Object.values(emailContent).every(v => !!v)

    if (isLoading) {
        return <LoadingPage/>
    }
    if (hasLoadingError) {
        return <NotFound/>
    }

    return <>

        <Helmet>
            <meta charSet="utf-8" />
            <title>{salesProfile.name} - Active Group</title>
        </Helmet>

        <div className="p-0 min-vh-100">
            <AgentInfo
                salesProfile={salesProfile}
                track={track}
                setEmailModalOpen={setEmailModalOpen}/>

            <ToastContainer/>
            <InventoryList
                inventoryList={salesProfile["properties"]}
                profilePhotoUrl={salesProfile.profile_photo_url}
                track={track}
                inquiry={inquiry}
                setEmailModalOpen={setEmailModalOpen}

                basicEmailFields={basicEmailFields}
                emailContentOk={emailContentOk}
                selectProperty={selectProperty}
            />
            <ParallaxProvider>
                <ActiveGroupInfo/>
            </ParallaxProvider>

            <AgentInfo
                bottom={true}
                salesProfile={salesProfile}
                track={track}
                setEmailModalOpen={setEmailModalOpen}/>


            <Email
                isOpen={isEmailModalOpen}
                profilePhotoUrl={salesProfile.profile_photo_url}
                setEmailModalOpen={setEmailModalOpen}
                basicEmailFields={basicEmailFields}
                inquiry={inquiry}
                emailContentOk={emailContentOk}
            />

            {
                announcement && <AnnouncementModal
                    isOpen={showAnnouncement}
                    toggle={() => {
                        setShowAnnouncement(false)
                    }}
                    onView={() => {
                        setAnnouncementSeen(true)
                        trackAnnouncement(announcement.id)
                    }}

                    profilePhotoUrl={salesProfile.profile_photo_url}
                    {...announcement}
                />
            }


            <div className="floating-inquiry-btn" onClick={() => {
                track(TRACK_INQUIRE)
                setEmailModalOpen(true)
            }}>
                <Row>
                    <Col className="col-auto pe-0"><ProfilePhoto shrink={3} url={salesProfile.profile_photo_url}/></Col>
                    <Col>
                        <strong>Inquire Today!</strong><br/>
                        Send me a message.
                    </Col>
                </Row>
            </div>

            <ScrollToTop/>
        </div>
    </>
}
