import React, {useState} from "react";
import {Col, Row} from "reactstrap";
import PropertyCarousel from "./propertyCarousel";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStreetView} from "@fortawesome/free-solid-svg-icons";
import "../../../assets/elegantFontCss/style.css";
import SiteMapModal from "./siteMapModal";
import ScheduleVisitModal from "./scheduleVisitModal";
import SampleComputationModal from "./sampleComputationModal";
import {
    TRACK_CAROUSEL,
    TRACK_LOCATION,
    TRACK_MORE_INFO,
    TRACK_SAMPLE_COMPUTATION,
    TRACK_VISIT
} from "../../../constants";


export default function Inventory(props) {
    const [showSitePlan, setShowSitePlan] = useState(false);
    const [showSiteVisit, setShowSiteVisit] = useState(false)
    const [showSampleComputation, setShowSampleComputation] = useState(false);


    return <>
        <Row className="mt-4 py-4 border-bottom px-3">
            <Col lg={9}>
                <PropertyCarousel
                    items={props.data.media}
                    onOpenCarousel={() => props.track(TRACK_CAROUSEL,  {property_group_id: props.data.id})}
                />
                <div dangerouslySetInnerHTML={{__html: props.data.description}} className="pb-4"></div>
            </Col>
            <Col>
                <Row>
                    <Col>
                        <img src={props.data.logo} alt={props.data.name + " logo"}
                             className={"img-fluid property-logo"}/>
                    </Col>
                </Row>
                <Row className="" onClick={() => {
                    props.track(TRACK_LOCATION, {property_group_id: props.data.id})
                    setShowSitePlan(true)
                }}>
                    <Col className="">
                        <div className="border-bottom justify-content-start align-items-center d-flex py-3">
                            <span className="icon_pin_alt property-action-icon"/>{" "}
                            <span className="property-action-text">
                                View location map and details to the site plan.
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row className="" onClick={() => {
                    props.track(TRACK_MORE_INFO, {property_group_id: props.data.id})
                    props.selectProperty(props.data.id)
                    props.setEmailModalOpen(true)
                }}>
                    <Col className="">
                        <div className="border-bottom justify-content-start align-items-center d-flex py-3">
                            <span className="icon_mail_alt property-action-icon"/>{" "}
                            <span className="property-action-text">
                            Request for more information about this property.
                        </span>
                        </div>
                    </Col>
                </Row>
                <Row className="" onClick={() => {
                    props.track(TRACK_VISIT, {property_group_id: props.data.id})
                    props.selectProperty(props.data.id)
                    setShowSiteVisit(true)
                }}>
                    <Col className="">
                        <div className="border-bottom justify-content-start align-items-center d-flex py-3">
                            <FontAwesomeIcon icon={faStreetView} className="property-action-icon"/>{" "}
                            <span className="property-action-text">
                        Schedule a site visit to this property.
                        </span>
                        </div>
                    </Col>
                </Row>
                <Row className="" onClick={() => {
                    props.track(TRACK_SAMPLE_COMPUTATION, {property_group_id: props.data.id})
                    props.selectProperty(props.data.id)
                    setShowSampleComputation(true)
                }}>
                    <Col className="">
                        <div className="border-bottom justify-content-start align-items-center d-flex py-3">
                            <span className="elegant-icons property-action-icon">&#xe0ee;</span>{" "}
                            <span className="property-action-text">
                        Request for a sample computation.
                        </span>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>

        <SiteMapModal
            open={showSitePlan}
            toggle={() => {
                setShowSitePlan(false)
            }}
            name={props.data.name}
            profilePhotoUrl={props.profilePhotoUrl}
            close={() => setShowSitePlan(false)}
            sitePlanPhotos={props.data.site_plan.site_plan_photos}
            googleMapUrl={props.data.site_plan.google_maps_embed_code}
        />

        <ScheduleVisitModal
            isOpen={showSiteVisit}
            profilePhotoUrl={props.profilePhotoUrl}
            setEmailModalOpen={setShowSiteVisit}
            inquiry={props.inquiry}
            basicEmailFields={props.basicEmailFields}
            emailContentOk={props.emailContentOk}
        />

        <SampleComputationModal
            isOpen={showSampleComputation}
            profilePhotoUrl={props.profilePhotoUrl}
            setEmailModalOpen={setShowSampleComputation}
            inquiry={props.inquiry}
            basicEmailFields={props.basicEmailFields}
            emailContentOk={props.emailContentOk}
        />

    </>
}