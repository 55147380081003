import React, {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import {Modal, ModalBody, ModalHeader} from "reactstrap";

export default function PrivacyPolicy(props) {
    const [content, setContent] = useState("")

    const fetchContent = () => {
        axios.get('view-privacy-policy/microsite/').then(response => {
            setContent(response.data.content)
        }).catch(err => {
            toast.error("Cannot fetch privacy policy.")
            throw err
        })
    }

    useEffect(() => {
        fetchContent()
    }, [])

    return <Modal
        isOpen={props.isOpen}
        size={"xl"}
        toggle={props.toggle}
        centered
        scrollable
        >
        <ModalHeader toggle={props.toggle}>
            <h3>Privacy Policy</h3>
        </ModalHeader>
        <ModalBody className="overflow-scroll">
            <div dangerouslySetInnerHTML={{__html: content}}/>
        </ModalBody>
    </Modal>
}