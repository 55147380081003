import React from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import {Carousel, CarouselControl, CarouselIndicators, CarouselItem, Col, Modal, ModalBody, Row,} from 'reactstrap';
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function PropertyCarousel(props) {

    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);
    const [isFullScreen, setIsFullScreen] = React.useState(false);

    const itemLength = props.items.length - 1

    // Previous button for Carousel
    const previousButton = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ?
            itemLength : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    // Next button for Carousel
    const nextButton = () => {
        if (animating) return;
        const nextIndex = activeIndex === itemLength ?
            0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    // Carousel Item Data
    const carouselItemData = props.items.map((item) => {
        return (
            <CarouselItem
                key={item.photo}
                onExited={() => setAnimating(false)}
                onExiting={() => setAnimating(true)}

            >
                <img
                    onClick={() => {
                        props.onOpenCarousel()
                        setIsFullScreen(!isFullScreen)
                    }}
                    src={item.photo} alt={item.title} className="img-fluid"/>
            </CarouselItem>
        );
    });

    const activeItem = props.items[activeIndex];

    if (!activeItem) {
        return null
    }

    return (
        <div>
            <Carousel
                className={"property-carousel"}
                previous={previousButton} next={nextButton}
                activeIndex={activeIndex} interval={null}>
                <CarouselIndicators
                    items={props.items}
                    activeIndex={activeIndex}
                    onClickHandler={(newIndex) => {
                        if (animating) return;
                        setActiveIndex(newIndex);
                    }}/>
                {carouselItemData}
                <CarouselControl
                    directionText="Prev"
                    direction="prev" onClickHandler={previousButton}/>
                <CarouselControl
                    directionText="Next"
                    direction="next" onClickHandler={nextButton}/>
            </Carousel>

            {
                <Modal
                    className="carousel-modal"
                    centered
                    isOpen={isFullScreen}
                    size="xl"
                    toggle={() => {
                        setIsFullScreen(false)
                    }}
                    backdropClassName={"carousel-modal-backdrop"}
                >
                    <ModalBody>
                        <Row className="text-end">
                            <Col>
                                <FontAwesomeIcon onClick={() => {setIsFullScreen(false)}} icon={faTimes} className="close-btn fa-2x"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Carousel
                                    previous={previousButton} next={nextButton}
                                    activeIndex={activeIndex} interval={null}>
                                    {carouselItemData}
                                    <CarouselControl
                                        directionText="Prev"
                                        direction="prev" onClickHandler={previousButton}/>
                                    <CarouselControl
                                        directionText="Next"
                                        direction="next" onClickHandler={nextButton}/>
                                </Carousel>
                            </Col>
                        </Row>
                        <Row className="active-item-info-row">
                            <Col>{activeItem.title}</Col>
                            <Col className="col-auto page-info">
                                {activeIndex + 1} of {itemLength + 1}
                            </Col>
                        </Row>

                    </ModalBody>
                </Modal>
            }
        </div>
    );
}

export default PropertyCarousel;
