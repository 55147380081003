import React, {useEffect} from "react";
import {Button, Col, Container, Modal, ModalBody, Row} from "reactstrap";
import AgentModalHeader from "./modalHeader";


export default function AnnouncementModal(props) {
    return <Modal
        centered scrollable
        isOpen={props.isOpen}
        size="lg"
        className="sales-profile-modal"
        toggle={() => {
            props.toggle(false)
        }}
        onOpened={() => {
            props.onView()
        }}
    >
        <AgentModalHeader
            title={props.title}
            subtitle={null}
            profilePhotoUrl={props.profilePhotoUrl}
            close={() => props.toggle(false)}/>
        <ModalBody className="h-100  min-vh-50">
            <Container className="px-4 py-2">
                <div dangerouslySetInnerHTML={{__html: props.content}}/>
            </Container>
        </ModalBody>
    </Modal>
}