import React, {useState} from "react";
import {Button, Col, Container, Modal, ModalBody, Row} from "reactstrap";
import AgentModalHeader from "../components/modalHeader";
import Calendar from "react-calendar";
import moment from "moment";
import Select from "react-select";
import ModalInquirySuccess from "../components/modalInquirySuccess";
import 'react-calendar/dist/Calendar.css';
import {reactSelectStyles} from "../../../utils";
import {INQUIRY_SITE_VISIT} from "../../../constants";
import PrivacyPolicy from "../../../commonComponents/privacyPolicy";

export default function ScheduleVisitModal(props) {
    const [isSending, setIsSending] = useState(false)
    const [isSent, setIsSent] = useState(false)

    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedTime, setSelectedTime] = useState("9:00 AM");
    const send = () => {
        setIsSending(true)
        props.inquiry({
            preferred_date: selectedDate,
            preferred_time: selectedTime,
            inquiry_type: INQUIRY_SITE_VISIT
        }).then(() => {
            setIsSent(true)
        }).finally(() => {
            setIsSending(false)
        })
    }

    if (isSent) {
        return <ModalInquirySuccess
            toggle={() => {
                props.setEmailModalOpen(false)
                setIsSending(false)
                setIsSent(false)
            }}
            url={props.profilePhotoUrl}
            isOpen={props.isOpen}
        />
    }

    const disabledDates = [
    ]


    const timeOptions = [
        {"value": "7:00 AM", "label": "7:00 AM"}, {"value": "7:30 AM", "label": "7:30 AM"},
        {"value": "8:00 AM", "label": "8:00 AM"}, {"value": "8:30 AM", "label": "8:30 AM"},
        {"value": "9:00 AM", "label": "9:00 AM"}, {"value": "9:30 AM", "label": "9:30 AM"},
        {"value": "10:00 AM", "label": "10:00 AM"}, {"value": "10:30 AM", "label": "10:30 AM"},
        {"value": "11:00 AM", "label": "11:00 AM"}, {"value": "11:30 AM", "label": "11:30 AM"},
        {"value": "12:00 PM", "label": "12:00 Noon"}, {"value": "12:30 PM", "label": "12:30 PM"},
        {"value": "1:00 PM", "label": "1:00 PM"}, {"value": "1:30 PM", "label": "1:30 PM"},
        {"value": "2:00 PM", "label": "2:00 PM"}, {"value": "2:30 PM", "label": "2:30 PM"},
        {"value": "3:00 PM", "label": "3:00 PM"}, {"value": "3:30 PM", "label": "3:30 PM"},
        {"value": "4:00 PM", "label": "4:00 PM"}, {"value": "4:30 PM", "label": "4:30 PM"},
        {"value": "5:00 PM", "label": "5:00 PM"}, {"value": "5:30 PM", "label": "5:30 PM"},
        {"value": "6:00 PM", "label": "6:00 PM"}, {"value": "6:30 PM", "label": "6:30 PM"},
        {"value": "7:00 PM", "label": "7:00 PM"}, {"value": "7:30 PM", "label": "7:30 PM"},
    ]
    const selectedTimeOption = selectedTime && timeOptions.filter(o => o.value === selectedTime)

    return <Modal
        centered scrollable
        isOpen={props.isOpen}
        size="xl"
        className="sales-profile-modal"
        toggle={() => {
            props.setEmailModalOpen(false)
        }}
    >
        <AgentModalHeader
            title={"Send me a message"}
            subtitle={"Let me help you find your next home or investment."}
            profilePhotoUrl={props.profilePhotoUrl}
            close={() => props.setEmailModalOpen(false)}/>
        <ModalBody className="h-100 pb-5">
            <Container className="px-4 py-2">
                <Row>
                    <Col lg={5}>
                        {props.basicEmailFields}
                    </Col>
                    <Col lg={7}>
                        <Row>
                            <Col>
                                <strong>Choose your preferred date</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Calendar
                                    tileDisabled={
                                        ({activeStartDate, date}) => {
                                            return disabledDates.indexOf(date.toString()) > -1
                                        }

                                    }
                                    onChange={(value, event) => {
                                        setSelectedDate(moment(value).format('YYYY-MM-DD'))
                                    }}
                                    value={selectedDate && moment(selectedDate, 'YYYY-MM-DD').toDate()}
                                    minDate={new Date()}
                                    className="site-visit"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="align-items-center d-flex">
                                Choose preferred time
                            </Col>
                            <Col>
                                <Select
                                    onChange={o => {
                                        setSelectedTime(o.value)
                                    }}
                                    styles={reactSelectStyles}
                                    value={selectedTimeOption}
                                    options={timeOptions}
                                    menuPlacement={"top"}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row className="pt-2 px-4 text-end">
                    <Col>
                        <PrivacyPolicy linkText={"By continuing, you agree to our Terms and Privacy Policy."}/>
                    </Col>
                </Row>
                <Row className="px-4 py-2 text-end">
                    <Col>
                        <Button size="lg" color={"primary"} disabled={isSending || !props.emailContentOk || !selectedDate} onClick={send}>
                            Send{isSending && "ing..."}
                        </Button>
                    </Col>
                </Row>

                <Row className="px-4 py-2 text-end">
                    <Col>
                        Please wait for my call to confirm your schedule. Thank you.
                    </Col>
                </Row>
            </Container>
        </ModalBody>
    </Modal>
}