import React from "react";
import {Col, Container, Row} from "reactstrap";
import ProfilePhoto from "../profilePhoto";


export default function AgentModalHeader(props) {
    return <div className="email-modal-header modal-header">
        <Container fluid>
            <Row className="p-4">
                <Col lg={9} className="align-items-center d-flex">
                    <div>
                        <h1>{props.title}</h1>
                        {props.subtitle}
                    </div>
                </Col>
                <Col lg={3} className="justify-content-end d-flex">
                    <ProfilePhoto url={props.profilePhotoUrl} shrink={1}/>
                </Col>
            </Row>
        </Container>

        <button type="button" className="btn-close-white btn-close align-top" data-bs-dismiss="modal" onClick={() => {
            props.close()
        }} aria-label="Close"></button>
    </div>
}